<template>
    <div id="app" class="bg-blue-start">
        <AppHeader ref="appHeader" v-if="showNavBar && isLoggedIn"></AppHeader>

        <notifications />

        <router-view />
    </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            showNavBar: true,
        };
    },
    components: {
        AppHeader,
    },
    computed: {
        ...mapGetters(["isLoggedIn"]),
        currentRoute() {
            return this.$route.name;
        },
    },
    watch: {
        currentRoute(val) {
            const excludedRoutesForNav = ["Pos"];
            if (excludedRoutesForNav.includes(val)) {
                this.showNavBar = false;
            } else {
                this.showNavBar = true;
            }
        },
    },

    setup() {},
};
</script>

<style lang="css">
.bg-blue-000 {
    background-color: #2f67ab;
}
.bg-blue-start {
    background-color: #e9f0fe;
}
.bg-blue-button {
    background-color: #d0e2f8;
}
.bg-red-button {
    background-color: #fbd0d5;
}
</style>
