import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    status: '',
    loadingStatus: false,
    token: localStorage.getItem('token') || "",
    user: [],
    staff: [],
    patient:[],
    items:[],
    clickedItems: []
    

  },
 
  mutations: {
    loadingStatus(state, newLoadingStatus){
      state.loadingStatus = newLoadingStatus

    },
    RESET_STATE(state) {
      state.clickedItems = [];
      state.staff = [];
      state.patient = [];
    },

    SET_CLICKED_ITEMS(state, clickedItems) {
      state.clickedItems = clickedItems
    },
    GET_STAFF(state, staff) {
      state.status = 'success'
      state.staff = staff

    },
    GET_PATIENT (state, patient){
      state.status = 'success'
      state.patient = patient

    },
    POST_ITEMS (state, items){
      state.status = 'success'
      state.items = items


    },
    GET_USER (state, user){
      state.status = 'success'
      state.user = user
    },
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, token){
      state.status = 'success'
      state.token = token
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = ''
      state.token = ''
    }

  },
  actions: {
       login({commit}, userdata){

        commit('loadingStatus', true)

        return new Promise((resolve, reject) => {
          axios.post(`${process.env.VUE_APP_SERVICE_URL}login/`,userdata.userdata)
          .then(resp => {
            const token = resp.data.data.token
            console.log(resp.data.data)
            const user = resp.data.data
            localStorage.setItem('token', token)
            localStorage.setItem('user', JSON.stringify(user)) // stringify obj in localstorage

            axios.defaults.headers.common['Authorization'] = token
            commit('auth_success', token)
            commit('GET_USER', user)
            commit('loadingStatus', false)
            resolve(resp)
          })
          .catch(err => {
   
            commit('loadingStatus', false)
            localStorage.removeItem('token')
            localStorage.removeItem('user') // remove user details on logout
            reject(err)

            
          })
      })
    },
    getStaff({commit},staffdata){

      const AuthStr = 'Token '.concat(localStorage.getItem('token'));
      commit('loadingStatus', true)


      return new Promise((resolve, reject) => {

      axios.get(`${process.env.VUE_APP_SERVICE_URL}get_staff/?staff_id=`+staffdata.staffdata.id+'',{ headers: { Authorization: AuthStr } })

      .then( resp => {
        const staff = resp.data.data.staff

        commit('GET_STAFF',staff)
        commit('loadingStatus', false)

        resolve(resp)
      })
      .catch(error => {

        commit('loadingStatus', false)

        reject(error)

        
      })

      })
    },
    getPatient({commit},patientdata){

      const AuthStr = 'Token '.concat(localStorage.getItem('token'));
      commit('loadingStatus', true)

      return new Promise((resolve, reject) => {

      axios.get(`${process.env.VUE_APP_SERVICE_URL}get_patient/?patient_id=`+patientdata.patientdata.id+'',{ headers: { Authorization: AuthStr } })

      .then( resp => {
        const patient = resp.data.data.patient
        commit('GET_PATIENT', patient)
        commit('loadingStatus', false)

        resolve(resp)

      })
      .catch(err => {
        console.log(err.message);

        commit('loadingStatus', false)

        reject(err)

        
      })

      })
    },
    resetState({commit}) {
      commit('RESET_STATE');
    },
    postItems({commit},journalItems){

      const AuthStr = 'Token '.concat(localStorage.getItem('token'));
      commit('loadingStatus', true)


      return new Promise((resolve, reject) => {
        axios.post(
          `${process.env.VUE_APP_SERVICE_URL}item_journal/`,journalItems.journalItems,{ headers:  { Authorization: AuthStr }  }) 
          .then(resp => {
      this.setState({data:resp.data});
      console.log(this.data.data);
      commit('GET_ITEM')
      commit('loadingStatus', false)

      resolve(resp)
    })
    .catch(err => {
      console.log(err.message);
      commit('loadingStatus', false)

      reject(err)

      
    })

    })
  },
    logout(context){
      return new Promise((resolve) => {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        delete axios.defaults.headers.common['Authorization']
        context.commit('logout')
        resolve()
      })
      
    },
    storeClickedItems({commit}, clickedItems) {
      commit('SET_CLICKED_ITEMS', clickedItems)
    },

  },
  getters : {
    isLoggedIn: state => state.token !== "",
    authStatus: state => state.status,

  patientget: state => state.patient, 
  staffget: state => state.staff,   
  loadingStatus: state =>state.loadingStatus,
  getUsername: state => JSON.parse(localStorage.getItem("user")) || state.user, // get user item from localstorage or fallback to store details



  },
  modules: {
  }
})
