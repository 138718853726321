<template>
  <div v-if="loadingStatus">
    <loading></loading>
  </div>
  <div
    class="relative z-10 flex-shrink-0 flex h-16 shadow"
    style="background-color: #3170bb"
  >
    <NavSideDropdown />

    <div class="text-white items-center p-4 hidden sm:block">
      <span>{{ getUsername.username }}</span>
    </div>
    <div class="flex-1 px-4 flex justify-between">
      <div class="flex-1 flex">
        <div class="w-full flex md:ml-0">
          <label class="sr-only">Search</label>
          <div class="relative w-full text-gray-400 focus-within:text-gray-600">
            <div
              class="absolute inset-y-0 left-0 flex items-center pointer-events-none"
            >
              <svg
                class="w-5 ml-2 text-gray-600 h-5 cursor-pointer"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </div>
            <input
              ref="search"
              v-model="search"
              @keyup.down="scrollDown"
              @keyup.up="scrollUp"
              @keyup.enter="scrollPushItem"
              class="block mt-3 w-full h-10 pl-8 pr-3 py-2 rounded-md border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
              placeholder="Search by Code or Name"
              :disabled="isDisabled"
            />
          </div>
        </div>
      </div>

      <div class="ml-4 flex items-center md:ml-6">
        <div
          class="inline-block bg-blue-000 py-2 px-2 border border-transparent rounded-md text-base font-medium text-white hover:bg-opacity-75"
        >
          <router-link to="/"> Logoff </router-link>
        </div>
      </div>
    </div>
  </div>
  <div class="flex">
    <div class="bg-white absolute" ref="searchResultWrapper">
      <div v-if="!search || !search.length"></div>
      <ul v-else ref="searchResultWrapper" class="searchResultWrapper">
        <li
          class="w-screen searchResultItem"
          v-for="(item, index) in visibleItems"
          :key="index"
          ref="searchResultItem"
        >
          <div
            class="rounded-md px-2 py-2 bg-white shadow"
            @click="onSelect(item)"
          >
            <p class="text-sm font-medium text-gray-900">
              {{ item.number }} {{ item.name }}
            </p>
            <div class="text-sm text-gray-500 truncate z-40">
              <span class="">Expiry: {{ item.expiry }} </span>
              <span class="mx-4">Qty: {{ item.stock }} </span>
              <span class="mx-4">Lot No:{{ item.lot_no }}</span>
              <span class="mx-4">UOM: {{ item.unitofMeasureCode }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="container mx-auto z-50">
    <div class="flex flex-col h-screen">
      <div v-if="loadingStatus">
        <loading></loading>
      </div>
      <header class="text-center">
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-3 mt-4">
          <User :staff="staffget"></User>
          <PatientComponent :patient="patientget"></PatientComponent>
          <!-- <Station :user="getUsername"></Station> -->
        </div>
      </header>
      <div
        class="flex-none overflow-x-auto h-2/3 search-table-wrapper bg-white mt-4"
      >
        <Item
          v-for="(item, idx) in clickedItems"
          @increment="onSelect(item)"
          @decrement="onSelect(item, true)"
          :item="item"
          :clickedItems="clickedItems"
          :idx="idx"
          :key="item.id"
          v-on:remove="onRemoveClicked"
          @updateQuantity="updateQuantity"
        ></Item>
      </div>
      <footer>
        <div class="flex justify-center sticky">
          <button
            type="submit"
            v-if="clickedItems.length > 0"
            @click="postItems"
            class="w-full items-center px-4 py-4 border border-transparent text-base font-medium rounded-md bg-blue-000 text-white hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-center"
          >
            TOUCH TO CONFIRM
          </button>
          <button
            v-else
            class="w-full items-center px-4 py-4 border border-transparent text-base font-medium rounded-md bg-gray-500 text-white text-center"
          >
            ADD ITEMS TO CONFIRM
          </button>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import User from "../components/User.vue";
import PatientComponent from "../components/PatientComponent.vue";
import Item from "../components/Item.vue";
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import { notify } from "@kyvg/vue3-notification";
import loading from "../components/loading.vue";
import NavSideDropdown from "../components/NavSideDropdown";

export default {
  data() {
    return {
      activeSearchResultItem: null,
      isDisabled: false,
      focus: null,
      items: [],
      visibleItems: [],
      search: "",
      staff: [],
      user: [],
    };
  },
  computed: {
    ...mapState(["clickedItems"]),
    ...mapGetters(["loadingStatus"]),
    ...mapGetters(["patientget"]),
    ...mapGetters(["staffget"]),
    ...mapGetters(["getUsername"]),
    ...mapGetters(["isLoggedIn"]),
    isDisable() {
      return this.clickedItems.length > 0;
    },
  },

  created() {
    const inRouteValidation =
      Object.keys(this.staffget).length == 0 ||
      Object.keys(this.patientget).length == 0;
    inRouteValidation ? this.$router.push("/") : "";
    const AuthStr = "Token ".concat(localStorage.getItem("token"));

    axios
      .get(`${process.env.VUE_APP_SERVICE_URL}search_item/`, {
        headers: { Authorization: AuthStr },
      })
      .then((res) => {
        for (const item of res.data.data.items) {
          this.items.push(item);
          console.log(res.data.data.items);
        }

        this.updateVisibleItems();
      });
  },
  watch: {
    search: function () {
      if (!this.search) {
        // reset item count
        this.activeSearchResultItem = null;
        return this.updateVisibleItems();
      }
      this.visibleItems = this.items
        .filter((item) => {
          const keyword = this.search.toLowerCase();

          // Search by string or number or serial_no
          if (
            item.name.toLowerCase().match(keyword) ||
            item.number.toLowerCase().match(keyword) ||
            item.item_search.toLowerCase().match(keyword) ||
            item.serial_no.toLowerCase().match(keyword)
          ) {
            // Return all matched result(s)
            return item;
          }
        })

        .slice(0, 10);
      // see if selected
      // if(this.visibleItems.length > 0) {
      //     this.$nextTick(() => {
      //         this.activeSearchResultItem = null
      //         this.scrollDown()
      //     })
      // }
    },
  },
  components: {
    User,
    Item,
    PatientComponent,
    loading,
    NavSideDropdown,
  },
  mounted() {
    this.focusInput();
  },
  beforeUnmount() {},

  methods: {
    updateQuantity({ value, idx }) {
      this.clickedItems[idx].qty = value;
    },
    scrollPushItem() {
      const current = this.activeSearchResultItem;

      // Select only item in list
      if (this.visibleItems.length === 1) {
        document
          .querySelectorAll(".searchResultItem")[0]
          .classList.add("bg-blue-400");
        setTimeout(() => {
          this.onSelect(this.visibleItems[0]);
        }, 1000);
      } else if (this.visibleItems.length > 1) {
        // Select item based on current index
        document
          .querySelectorAll(".searchResultItem")
          [current].classList.add("bg-blue-400");
        setTimeout(() => {
          this.onSelect(this.visibleItems[current]);
        }, 1000);
      }
    },
    scrollDown() {
      try {
        let current = this.activeSearchResultItem;
        const all = document.querySelectorAll(".searchResultItem");
        if (current == all.length - 1) {
          this.activeSearchResultItem = 0;

          // BUG: remove last index's background color
          all[all.length - 1].firstChild.classList.remove("bg-blue-400");

          // update current
          current = this.activeSearchResultItem;
        } else if (current == null) {
          this.activeSearchResultItem = 0;

          // update current
          current = this.activeSearchResultItem;
        } else {
          this.activeSearchResultItem++;

          // update current
          current = this.activeSearchResultItem;
        }
        console.log(current);
        if (current > 0) {
          // remove previous item background color
          const prevSelected = all[current - 1].firstChild;
          prevSelected.classList.remove("bg-blue-400");
        }
        if (current == all.length + 1) {
          // re-start scroll from top
          current = 0;
        }
        const selected = all[current].firstChild;
        selected.classList.add("bg-blue-400");
      } catch (err) {
        console.log(err);
        console.log(err.message);
      }
    },
    scrollUp() {
      const inheritedIndex = this.activeSearchResultItem;
      let current = this.activeSearchResultItem;
      const all = document.querySelectorAll(".searchResultItem");
      if (current > 0) {
        // subtract only from second item
        this.activeSearchResultItem--;

        // update current
        current = this.activeSearchResultItem;
      } else if (current == 0) {
        // scroll to last item/index
        this.activeSearchResultItem = all.length - 1;

        // update current
        current = this.activeSearchResultItem;
      }
      let selected;

      // Remove background from bottom item
      if (current != inheritedIndex) {
        const prevSelected = all[inheritedIndex].firstChild;
        prevSelected.classList.remove("bg-blue-400");
      }

      selected = all[current].firstChild;
      selected.classList.add("bg-blue-400");
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    postItems: function () {
      this.$store.state.loadingStatus = true;

      const AuthStr = "Token ".concat(localStorage.getItem("token"));

      let journalItems = JSON.stringify({
        items: this.clickedItems,
        staff: this.staffget.id,
        patient: this.patientget.id,
      });

      axios
        .post(`${process.env.VUE_APP_SERVICE_URL}item_journal/`, journalItems, {
          headers: {
            Authorization: AuthStr,
            "Content-Type": "application/json",
          },
        })
        .then((resp) => {
          if (resp.status === 200) {
            this.$notify({
              title: "Successful",
              text: "Items Posted!",
              type: "success",
            });
            this.$router.push("/");
            this.$store.dispatch("resetState");
            this.$store.state.loadingStatus = false;
          }
        })
        .catch(function (error) {
          if (error.response) {
            notify({
              title: error.response.data.detail,
              type: "error",
            });
          }
        });
    },
    onRemoveClicked(idx) {
      this.clickedItems.splice(idx, 1);
      this.$refs.search.focus();
    },

    focusInput() {
      this.$refs.search.focus();
    },
    updateVisibleItems() {
      this.visibleItems = this.items;
    },
    onSelect(item, decrement) {
      const index = this.clickedItems.findIndex((x) => x.id === item.id);
      const itemStock = item.stock;
      const currentQty = item.qty || 0;
      this.isDisabled = true;
      setTimeout(() => {
        this.isDisabled = false;
      }, 1000);
      setTimeout(() => {
        this.$refs.search.focus();
      }, 1000);

      this.search = "";

      if (itemStock < 1) {
        return alert("Item is out of stock");
      }

      if (decrement) {
        if (index >= 0) {
          this.clickedItems[index].qty -= 1;

          // Update clickedItems in Store
          return this.$store.dispatch("storeClickedItems", this.clickedItems);
        }
      }

      if (currentQty < itemStock) {
        if (index >= 0) {
          return (this.clickedItems[index].qty += 1);
        } else {
          item.qty = 1;

          this.clickedItems.push(item);

          this.search = "";
          setTimeout(() => {
            this.$refs.search.focus();
          }, 1000);

          // Update clickedItems in Store
          this.$store.dispatch("storeClickedItems", this.clickedItems);
        }
      }
    },
  },
};
</script>

<style lang="css" scoped>
.dropdown:hover .dropdown-menu {
  display: block;
}
</style>
