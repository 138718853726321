<template>

  <div class="bg-white rounded-lg px-6 py-2 ">
      <ul class="text-left	"> 
        <li class="text-sm text-gray-500">Staff</li>
        <li class="text-md text-blue-600">{{staff.name}}</li>
        <li class="text-md text-gray-500">ID {{staff.id}} </li>
      </ul>

  </div>


</template>

<script >

export default{
  props: {
      staff: {
      type: Object,
    }
  },
  
  
  computed:{
  },
}

</script>

<style lang="scss" scoped>

</style>