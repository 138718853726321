<template>
  <div class="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <!-- <img class="mx-auto h-12 w-auto" src="https://pbs.twimg.com/profile_images/1051013775598346240/TgD1OjK-.jpg" alt="ADK Hospital"> -->
    <div v-if="loadingStatus">
      <loading></loading>
    </div>
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
          <h2
            class="mt-1 text-center text-3xl font-extrabold text-gray-900 mb-4"
          >
            Search Patient / Station ID
          </h2>
        </div>
        <form
          class="flex items-center flex-grow-0 flex-shrink pl-2 relative w-full border rounded-full px-1 py-1"
          @submit.prevent="getPatient"
        >
          <div class="block flex-grow flex-shrink overflow-hidden">
            <input
              ref="search"
              v-model="patientdata.id"
              class="w-full bg-transparent focus:outline-none focus:border-transparent"
              type="text"
              placeholder="Please Scan your Patient ID or Station ID"
            />
          </div>
          <button
            type="submit"
            class="flex items-center justify-center relative h-8 w-8 rounded-full"
          >
            <svg
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="presentation"
              focusable="false"
              style="
                display: block;
                fill: none;
                height: 12px;
                width: 12px;
                stroke: currentcolor;
                stroke-width: 5.33333;
                overflow: visible;
              "
            >
              <g fill="none">
                <path
                  d="m13 24c6.0751322 0 11-4.9248678 11-11 0-6.07513225-4.9248678-11-11-11-6.07513225 0-11 4.92486775-11 11 0 6.0751322 4.92486775 11 11 11zm8-3 9 9"
                ></path>
              </g>
            </svg>
          </button>

          <div></div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { notify } from "@kyvg/vue3-notification";
import { mapGetters } from "vuex";
import loading from "../components/loading.vue";

export default {
  data() {
    return {
      patientdata: {
        id: "",
        name: "",
        identification: "",
      },
    };
  },
  computed: {
    ...mapGetters(["loadingStatus"]),
  },
  components: { loading },
  mounted() {
    this.focusInput();
  },
  methods: {
    getPatient() {
      const patientdata = this.patientdata;
      this.$store
        .dispatch("getPatient", { patientdata })
        .then(() => this.$router.push("/pos"))

        .catch(function (error) {
          if (error.response) {
            notify({
              title: error.response.data.detail,
              type: "error",
            });
          }
        });
    },
    focusInput() {
      this.$refs.search.focus();
    },
  },
};
</script>

<style lang="scss" scoped></style>
