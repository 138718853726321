<template>
  <div>
      <!-- <pre>getNavigation: {{ getNavigation }}</pre> -->
      <!-- {{ isRouteItems }} -->
    <Menu as="div" class="p-2 ml-3 relative ">
      <div>
        <MenuButton class="flex items-center p-2 rounded-md bg-blue-000">
          <span class="sr-only">Open user menu</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#FFFFFF"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </MenuButton>
      </div>
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="
            origin-top-left
            absolute
            left-0
            mt-2
            w-48
            rounded-md
            shadow-lg
            py-1
            bg-white
            ring-1 ring-black ring-opacity-5
            focus:outline-none
          "
        >
          <MenuItem>
            <span class="block px-4 py-2">{{ getUsername.username }}</span>
          </MenuItem>
          <MenuItem
            v-for="link in navigation"
            :key="link.name"
            v-slot="{ active }"
          >
            <router-link
              v-if="link.name != 'Settings'"
              :to="link.to"
              :class="[
                active ? 'bg-gray-100' : '',
                'block px-4 py-2 text-sm text-gray-700',
                `${link.name}`,
              ]"
              :style="[
                currentRoute == 'Items' && link.name == 'POS' ? { 'display': 'block'} : {}
              ]"
            >
              {{ link.name }}
            </router-link>
            <div
              v-else
              :class="[
                active ? 'bg-gray-100' : '',
                'hover:cursor-auto dropdown block px-4 py-2 text-sm text-gray-700',
              ]"
            >
              <button>{{ link.name }}</button>
              <div class="dropdown-menu absolute hidden h-auto flex pt-4">
                <div class="block w-full bg-white shadow rounded-md px-14">
                  <button
                    v-if="isLoggedIn"
                    @click="logout"
                    class="block block px-4 py-2 text-sm text-gray-700"
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </MenuItem>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
export default {
  data() {
    return {
      navigation: [
        { name: "Home", to: "/" },
        { name: "Items", to: "/items" },
        { name: "POS", to: "/pos" },
        { name: "History", to: "/history" },
        { name: "Settings", to: ""},
      ],
      excluded: ["Settngs"]
    };
  },

  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
  updated() {
      if (this.isRouteItems) {
          this.excluded.push("POS")
        // this.navigation = this.navigation.filter(nav => nav.name != "POS")
      } else {
          this.excluded.pop("POS")
        // this.navigation = this.navigation.filter(nav => nav.name != "POS")
      }
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
    ...mapGetters(["getUsername"]),
    currentRoute() {
      const current = this.$route.name
        return current
        // return current.toLowerCase() || ''
    },
    isRouteItems() {
        const name = this.$route.name
        switch(name) {
            case "Items":
                return true
            default:
                return false

        }
    },
    getNavigation: {
        cache: false,
        get() {
            console.log(this.navigation)
            return this.navigation
        }
    }
  },
    methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
  watch: {
      currentRoute(val) {
        const routeName = val.toLowerCase()
            console.log(routeName)

        if (routeName === "items") {
            // this.navigation = this.navigation.filter(nav => nav.name != "POS")
            document.querySelector(".POS").style.display = 'block';
            console.log(this.navigation)
        }
      }
  },
};
</script>

<style lang="css" scoped>
.dropdown:hover .dropdown-menu {
  display: block;
}
.POS {
    display: none;
}
</style>