<template>
  <div
    class="relative z-10 flex-shrink-0 flex h-16 shadow"
    style="background-color: #3170bb"
  >
  <NavSideDropdown />
    <div class="text-white items-center p-4 hidden sm:block">
      <span>{{ getUsername.username }}</span>
    </div>
    <div class="flex-1 px-4 flex justify-between">
      <div class="flex-1 flex">
        <div class="w-full flex md:ml-0"></div>
      </div>

      <div class="ml-4 flex items-center md:ml-6">
        <div
          class="
            inline-block
            bg-blue-000
            py-2
            px-2
            border border-transparent
            rounded-md
            text-base
            font-medium
            text-white
            hover:bg-opacity-75
          "
        >
          <router-link to="/"> Logoff </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NavSideDropdown from '../components/NavSideDropdown';

const navigation = [
  { name: "Home", to: "/" },
  // { name: 'Login', to: '/login' },
  // { name: 'Patient', to: '/patient' },
  // { name: 'POS', to: '/pos' },
  { name: "Items", to: "/items" },
];

const setting = [{ name: "Settings", to: "" }];

export default {
  components: { NavSideDropdown },
  props: {
    Search: {
      type: Boolean,
    },
  },
  data() {
    return {
      search: "",
    };
  },

  computed: {
    ...mapGetters(["isLoggedIn"]),
    ...mapGetters(["getUsername"]),
  },

  setup() {
    return {
      navigation,
      setting,
    };
  },
};
</script>

<style lang="css" scoped>
.dropdown:hover .dropdown-menu {
  display: block;
}
</style>