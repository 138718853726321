<template>
  <!-- <AppItemHeader></AppItemHeader> -->
  <div>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="
              shadow
              overflow-hidden
              border-b border-gray-200
              sm:rounded-lg
            "
          >
            <table class="max-w-screen divide-y divide-gray-200">
              <thead class="bg-blue-200">
                <tr class="">
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Bin Code
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Employee ID
                  </th>
                                    <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Reg Date
                  </th>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Items
                  </th>

                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(transection, index) in transections"
                  :key="transection.id"
                  :class="
                    index % 2 === 0
                      ? 'bg-white bg-opacity-25'
                      : 'bg-gray-50 bg-opacity-5'
                  "
                >
                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm
                      font-medium
                      text-gray-900
                    "
                  >
                    {{ transection.id }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ transection.bin_code }}
                  </td>
                 <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{ transection.employee_id }}
                  </td>
                                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {{transection.registering_date}}
                </td>
                    <td v-for="(item, index) in transection.items" :key="index" class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <p>Item No:{{item.item_no}}<br>  Lot No: {{item.lot_no}}<br>  patient no: {{item.patient_no}} <br> Exp Date: {{item.expiration_date}}<br>  Qty: {{item.quantity}}<br> </p><br> 
                  </td>

                  
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { notify } from "@kyvg/vue3-notification";
// import AppItemHeader from "../components/AppItemHeader.vue";

export default {
  data() {
    return {
      transections: [],
    };
  },

//   components: { AppItemHeader },
  created() {
    const AuthStr = "Token ".concat(localStorage.getItem("token"));

    axios
      .get(`${process.env.VUE_APP_SERVICE_URL}get_transections/`, {
        headers: { Authorization: AuthStr },
      })
      .then((response) => (this.transections = response.data.data.transections, console.log(response.data.data.transections))
      
      )
      .catch(function (error) {
        if (error.response) {
          notify({
            title: error.response.data.detail,
            type: "error",
          });
        }
      });
  },
};
</script>
