import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import store from './store'
import Notifications from '@kyvg/vue3-notification'
import axios from "axios";


// Request Interceptor
axios.interceptors.response.use((response) => {
    return response;
  }, function (err) {
    const errStatus = err.response.status
    if (errStatus === 401) {
          console.log(1);
          store.dispatch("logout")
          window.location.href = "/login"
    }
    return Promise.reject(err)
  })
  

router.beforeEach((to, from, next) => {
    const validity = store.getters.isLoggedIn;

    if(to.matched.some((record) => record.meta.requiresAuth)) {
        if (!validity) {
            next({path: "/login"})
        } else {
            next();
        }
    } 
    else if(to.matched.some((record) => record.meta.requiresVisitor)) {
        // console.log("validity:", validity, "validity should be false")
        // console.log(validity);
        if (validity) {
            next({path: "/pos"})
        } else {
            next()
        }
    }
    
    else {
        next();
    }
})

createApp(App).use(Notifications).use(store).use(router).mount('#app')