<template>


  <div class="bg-white rounded-lg px-6 py-2 ">
      <ul class="text-left	"> 
        <li class="text-sm text-gray-500">{{patient.type}}</li>
        <li class="text-md text-blue-600">{{patient.name}}</li>
        <li class="text-md text-gray-500">{{patient.id}}</li>
      </ul>

  </div>


</template>

<script >






export default{

 props: {
     patient:{
       type: Object
     }
   },
   

}
</script>

<style lang="scss" scoped>

</style>