<template>
  <div class="flex flex-col mt-4">
    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="sm:rounded-lg">
          <table class="min-w-full">
            <colgroup>
              <col span="1" style="width: 150px" />
              <col span="1" style="width: 60%" />

              <col span="1" class="w-min" />
            </colgroup>
            <thead v-if="idx == 0" class="bg-gray-200">
              <tr>
                <th
                  scope="col"
                  class="mb-32 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-10"
                >
                  Code
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Name
                </th>
                <th scope="col" class="px-6 py-3 bg-gray-200"></th>
              </tr>
            </thead>
            <tbody>
              <tr track-by="idx" class="bg-white">
                <td class="px-6 text-sm font-medium text-gray-900">
                  {{ item.number }}
                </td>
                <td class="text-sm">
                  <span>{{ item.name }}</span> <br />
                  <span class="text-gray-500 mr-4">
                    Expiry: {{ item.expiry }}</span
                  >
                  <span class="text-gray-500 ml-4 mr-4">
                    Lot: {{ item.lot_no }}</span
                  >

                  <span class="text-gray-500 ml-4 mr-4">
                    UOM: {{ item.unitofMeasureCode }}</span
                  >
                </td>
                <td
                  class="px-6 text-right pt-4 text-sm font-medium mr-6 flex items-center justify-start"
                >
                  <button
                    class="px-3 py-3 rounded-md bg-blue-button text-white mr-2"
                    @click="decrement(item)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#2F67AB"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                  </button>
                  <div
                    class="text-blue-900 items-center w-12 justify-center ml-12"
                  >
                    <input
                      class="inputQty"
                      type="number"
                      ref="inputQty"
                      :value="item.qty"
                      @change="updateQty"
                      @keyup.enter="updateQty"
                    />
                  </div>

                  <button
                    :disabled="item.qty === item.stock"
                    class="px-3 py-3 rounded-md bg-blue-button text-white ml-6 mr-6"
                    :class="[item.stock === item.qty ? 'bg-yellow-100' : '']"
                    @click="increment(item)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#2F67AB"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <line x1="12" y1="5" x2="12" y2="19"></line>
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                  </button>

                  <img
                    width="24"
                    class="items-center justify-center"
                    :class="[item.qty > 1 ? 'visible' : 'invisible']"
                    height="24"
                    src="https://image.flaticon.com/icons/png/512/115/115107.png"
                    alt=""
                  />
                  <button
                    class="px-3 py-3 rounded-md bg-red-button text-white ml-6 mr-6"
                    v-on:click.prevent.stop="onRemove(idx)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#8E0102"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <polyline points="3 6 5 6 21 6"></polyline>
                      <path
                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                      ></path>
                      <line x1="10" y1="11" x2="10" y2="17"></line>
                      <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
    idx: {
      type: Number,
    },
  },

  data() {
    return {
      quantity: 0,
    };
  },

  watch: {
    quantity: function (val) {
      this.$emit("updateQuantity", val);
    },
  },

  methods: {
    updateQty() {
      let value = this.$refs.inputQty.value;
      const idx = this.idx;
      const stock = parseInt(this.item.stock);

      const data = {};
      data.value = parseInt(value);
      data.idx = idx;

      if (value <= stock) {
        this.$emit("updateQuantity", data);
      } else {
        this.$refs.inputQty.value = stock;
      }
    },
    onRemove(item) {
      this.$emit("remove", item);
    },
    increment(item) {
      const itemStock = item.stock;
      const currentQty = item.qty || 0;

      if (currentQty < itemStock) {
        this.$emit("increment", item);
      }
    },
    decrement(item) {
      const currentQty = item.qty || 0;

      if (currentQty > 0) {
        this.$emit("decrement", item);
      }
    },
  },
};
</script>

<style lang="css" scoped>
.inputQty {
  width: 50px;
  text-align: center;
}
</style>
