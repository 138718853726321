import { createWebHistory, createRouter } from 'vue-router';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import Patient from '@/views/Patient.vue';
import Pos from '@/views/Pos.vue';
import Items from '@/views/Items.vue';
import History from '@/views/History.vue';




const routes = [
	{
		path: '/',
		name: 'Home',
		// redirect: "/login",
		component: Home,
		meta: {
			requiresAuth: true
		}
    },
    {
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			requiresVisitor: true
		}

	},
	{
		path: '/patient',
		name: 'Patient',
		component: Patient,
		meta: {
			requiresAuth: true
		}
    },
    {
		path: '/pos',
		name: 'Pos',
		component: Pos,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/items',
		name: 'Items',
		component: Items,
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/history',
		name: 'History',
		component: History,
		meta: {
			requiresAuth: true
		}
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	
});

export default router;

